<template>
	<!-- eslint-disable -->

	<z-tool-bar />
</template>

<script>
/* eslint-disable */
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";
import ZToolBar from "./ZToolBar.vue";

export default {
	components: { "z-tool-bar": ZToolBar },
	name: "DashboardCoreAppBar",
	data() {
		return {
			user_name: localStorage.getItem("name")
		};
	},

	props: {
		value: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		company: ["Munjz LLC."],
		items2: [
			{
				icon: "mdi-chart-bar-stacked",
				title: "dashboard",
				to: "/home/dashbaord"
			},
			{
				icon: "mdi-order-bool-ascending-variant",
				title: "Orders",

				to: "/Order"
			},
			// {
			// 	icon: "mdi-clipboard-check",
			// 	title: "Admins",
			// 	to: "/admins"
			// },
			{
				icon: "mdi-archive",
				title: "Invoices",
				to: "/invoice"
			},
			{
				icon: "mdi-wallet",
				title: "My Wallet",
				to: "/my-wallet"
			},
			// 		{
			// 	icon: "mdi-account",
			// 	title: "Profile",
			// 	to: "/profile"
			// },
			{
				icon: "mdi-hammer-wrench",
				title: "Services",
				to: "/services"
			},

			{
				icon: "mdi-account-group",
				title: "Teams",
				to: "/teams"
			},
			{
				icon: "mdi-archive",
				title: "Branches",
				to: "/B2bBranches"
			},

			{
				icon: "mdi-cog-transfer",
				title: "Settings",
				to: "/settings"
			}
		]
	}),

	computed: {
		...mapState(["drawer"]),
		computedItems2() {
			return this.items2.map(this.mapItem2);
		}
	},
	mounted() {
		this.user_type = localStorage.getItem("type");
	},
	methods: {
		...mapMutations({
			setDrawer: "SET_DRAWER"
		}),
		mapItem(item) {
			return {
				...item,
				children: item.children ? item.children.map(this.mapItem) : undefined,
				title: this.$t(item.title)
			};
		},
		mapItem2(item) {
			return {
				...item,
				children: item.children ? item.children.map(this.mapItem) : undefined,
				title: this.$t(item.title)
			};
		}
	}
};
</script>
<style scoped>
.base-item {
	color: #ffffff;
}
.menuu {
	background-color: transparent;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
	color: appbar-text-color !important;
}
.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
	font-weight: bold !important;
}
.v-icon {
	color: #ffffff !important;
}
</style>

